/*text area*/
/* body{
    background: url(MicrosoftTeams-image.png) !important;
  background-repeat: no-repeat;
  background-size: auto;
} */




.editorWidth2 {
    width: 100% !important;
    height: 56vh !important;
    cursor: text;
    border: 0px solid #CCC;
    box-sizing: border-box;
    border-radius: 1px;
    z-index: -1;
    background: #fafafa; /*bg-color*/
    /* box-shadow: inset 0 0 5px #323232; */
}
.defaultEditorStyle2 {
    color: #090909 !important;
}
    /*text area*/

    /* model */
    
    .card2{
        border: 0px solid #fefefe; 
        border-radius: 2px;
        /* background: #ffffff; bg-color */
        height: 70vh !important;
    }
.add-m{
    margin-bottom: 50px;
}
     /* model */

     /* bg */
 
 .bg-common{
    /* width: 48% !important; */   
    height: 100vh;
    
    /* margin:0 !important;
    padding:0 !important; */
}
.one{
    /* background-color: #f9fbfc; */
    background-color: #F7F7F7cf;
    top: o;
    left: 0;
}
.title-yellow-bg{
    margin-top:  10px !important;
}

.two{
    background-color: #ffd461cf;

    /* background-image: 
    url("https://img.freepik.com/premium-vector/machine-learning-vector-website-template-landing-page-design-website-mobile-site-development-artificial-intelligence-robotics-cyber-mind-technology-concept-with-robot-character_317038-270.jpg?w=740"); */
    top: 0;
    right: 0;
}

/* .content-div{
    height: 100px !important;
    border: 1px solid rgb(225, 0, 0);
    background-color: #5f5f5f;
    z-index: 99 !important;
    top: 0; left: 0;
    transform: translate(-50%,-50%);
    box-shadow: 0px 0px 25px #5f5f5f;
} */
.card22{
    background-color: #f9fbfc;
    height: 70vh !important;
    z-index: 1 !important;
    top: 50%; left: 50%;
    transform: translate(-50%,-50%);
    border: 0.5px solid #CCC !important;
    border-radius:5px !important;
    /* box-shadow: 0 18px 18px -8px rgba(0, 0, 0, 0.328);
    -webkit-box-shadow: 0 18px 18px -8px rgba(0, 0, 0, 0.328);
    -moz-box-shadow: 0 18px 18px -8px rgba(0, 0, 0, 0.328); */
}
/* bg */
