body {
  /* background: #FFF url('images/MicrosoftTeams-image.png') top center fixed !important; */
  /* background-color: #c0c1c3 !important; */
  background-color: #edf0f5 !important
  /* background-color: #F1F3F6 !important; */
  /* background: #FAFAFA url('images/main-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #FAFAFA;
  min-height: 100vh;
  display: flex;
  flex-direction: column; 
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
  color: rgb(12, 11, 11);
}

.App-link {
  color: #61dafb;
}

.defaultEditorStyle {
  color: rgb(15, 15, 15);
  font-family: hind_vadodara_regular,hind_vadodara_light,calibri;
  font-size: 20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Style for editor */
.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-family: hind_vadodara_regular,hind_vadodara_light,calibri;
  font-size: 16px;
  padding: 15px;
}

.RichEditor-editor {
  /* border-top: 1px solid #ddd;
  cursor: text; */
  font-family: hind_vadodara_regular,hind_vadodara_light,calibri;
  font-size: 16px;
  margin-top: 10px;
  /* padding: 5px; */
}

.RichEditor-editor .public-DraftEditorPlaceholder-root {
  overflow-wrap: anywhere !important;
  overflow-y: auto;
  padding: 1em;
}

.RichEditor-editor .public-DraftEditor-content {
  overflow-wrap: anywhere !important;
  /* overflow-y: auto;
  max-height: 350px; */
  /* min-height: 30px; */
  padding: 1em;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditor-controls {
  /*font-family: 'Helvetica', sans-serif;*/
  font-size: 16px;
  margin-top: 10px;
  border:1px solid #808080;
  padding: 5px;
}

.RichEditor-styleButton {
  color: #999;
  font-size: 13px;
  /*font-weight: bold;*/
  cursor: pointer;
  margin: 0 5px 0 0;
  padding: 5px 0px;
  display: inline-block;
  /*border-right: 2px solid #d9d9d9;*/
}

.RichEditor-activeButton {
  color: #5890ff;
}

.bg {
  background-color: #FAFAFA;
}
.completions-container {
  border: 1px solid var(--gray-300);
  border-radius: 3px;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
  transition: -webkit-filter .3s;
  transition: filter .3s;
  transition: filter .3s,-webkit-filter .3s;
  width: 100%;
}
.edit-container {
  cursor: text;
  font-size: 16px;
  height: 100%;
  box-sizing: border-box;
}
.editorWidth {
  width: 100% !important;
  padding: 10px 0 10px 5px;
  /* width: 98% !important; */
  /* min-height: 30px; */
  /* height: 300px;
  overflow-y: auto; */
  cursor: text;
  background-color: #FFFFFF;
  /* border:1px solid #808080; */
  border:1px solid #CCC;
  border-radius: 8px;
  box-sizing: border-box;
  /*-webkit-box-shadow: 0 0 5px 0 rgba(82, 63, 105, .15);
  box-shadow: 0 5px 15px 0 rgba(82, 63, 105, .15);*/
  /* -webkit-box-shadow: 0 10px 25px #00000080; */
  
  /* box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25); */
  /* box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.25); - last updated*/
  /* box-shadow: inset 0 0 10px #a1b2c0 !important; */
  /* margin-left: 0.5%;
  margin-right: 0.5%;
  margin-bottom:1rem;*/
}
.controlWidth {
  width: 100% !important;
  user-select: none;
}

/*Editor text effect*/
.superFancyBlockquote {
  color: rgb(26, 26, 26);
  font-family: 'Hoefler Text', Georgia, serif;
  font-style: italic;
  /* background-color:green; */
}

/**/
.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.customBtn {
  font-size: 11px !important;
  line-height: 1.2;
}

.dropdown-toggle.btn::after, .dropdown-toggle.nav-link::after {
  content: none !important;
}
.daterangepicker {
  z-index: 1001 !important;
}
/* .navbar-nav .nav-link {
  font-size: 12pt;
} */
.table td, .table th {
  vertical-align: middle !important;
}
.css-yk16xz-control {
  min-height: 39px !important;
  border: 1px solid #e4e6ef !important;
}
.css-yk16xz-control:hover {
  border: 1px solid #e4e6ef !important;
}
.css-g1d714-ValueContainer {
  padding: 4px 8px !important;
}
.fs-8 {
  font-size: 8px !important;
}
.fs-9 {
  font-size: 9px !important;
}
.fs-10 {
font-size: 10px !important;
}
.fs-11 {
  font-size: 11px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.text-green {
  color: #50cd89 !important;
}
.input-group-border-radius-5 {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.border-radius-5 {
  border-radius: 5px !important;
}
.border-top-dashed {
  border-top: 1px dashed #ebedf3 !important;
}
.border-bottom-dashed {
  border-bottom: 1px dashed #ebedf3 !important;
}
.radio-inline .radio {
  margin-right: 1.50rem !important;
}
.input-group-sm>.searchBox {
  height: calc(1.35em + 1.1rem + 1px) !important;
}
.react-autosuggest__container {
  position: relative;
}
.react-autosuggest__input {
  width: 210px !important;
  height: calc(1.35em + 1.1rem + 2px) !important;
  padding: .55rem .75rem .55rem 28px;
  font-size: .925rem;
  line-height: 1.35;
  border: 1px solid #e4e6ef;
  border-radius: .28rem;
  background-color: #f3f6f9;
}
.react-autosuggest__input--focused {
  outline: none;
  border: 1px solid #e4e6ef !important;
}
.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.react-autosuggest__suggestions-container {
  display: none;
}
.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  width: 210px !important;
  border: 1px solid #e4e6ef;
  border-top: 0;
  background-color: #fff;
  font-size: .925rem;
  border-bottom-left-radius: .28rem;
  border-bottom-right-radius: .28rem;
  z-index: 2;
  max-height: 250px !important;
  overflow-y: auto;
}
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 5px 10px;
}
.react-autosuggest__suggestion--highlighted {
  background-color: #ddd;
}
.react-autosuggest__suggestion-match {
  color: #861b3e !important;
  font-weight: 500;
}
.srchicon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 24px;
  height: 24px;
}
.no-suggestions {
  display: block;
  position: absolute;
  margin-top: 62px;
  padding: 5px 10px;
  width: 210px !important;
  border: 1px solid #e4e6ef;
  background-color: #fff;
  font-size: .925rem;
  border-bottom-left-radius: .28rem;
  border-bottom-right-radius: .28rem;
  z-index: 2;
}
.cursor-na {
  cursor: not-allowed !important;
}
.top-9 {
  top: 9px !important;
}
.top-10 {
  top: 10px !important;
}
.left-10 {
  left: 10px !important;
}
.custom_bg {
  /*color: #861B3E !important;*/
  color: #941E46 !important;
}
.bg-light-grey {
  background-color: #F6F6F6 !important;
}
.border-radius-5 {
  border-radius: 4.48px !important;
}
.border-form-control {
  border: 1px solid #e4e6ef !important;
}

/*Login form*/
.login.login-6 .login-divider > div {
  height: 400px;
  background: #fff;
  width: 30px;
  box-shadow: -30px 0 35px -7px rgba(0,0,0,.05);
}
.login-form {
  width: 300px;
  margin: 75px auto 0;
  font-size: 15px;
}
.login-form form {
  /* margin-bottom: 15px; */
  /* background: #f7f7f7; */
  background: #FFF;
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3); */
  box-shadow: 0 0 10px #b8cad8 !important;
  padding: 30px;
}
.zoomIn {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
}
.animated {
  -webkit-animation-duration: 1s;
animation-duration: 1s;
-webkit-animation-fill-mode: both;
animation-fill-mode: both;
}
.login-form h2 {
  margin: 0 0 15px;
}
.form-control, .btn {
  min-height: 38px;
  border-radius: 2px;
}
.btn {        
  font-size: 15px;
  font-weight: bold;
}

/*Login form*/
/* canvas */
.offcanvasWrapper {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  flex: 1;
  flex-grow: 1;
  flex-flow: row-reverse;
}
.offcanvas {
  background: #ffffff;
  flex: 0.4;
  height: 100vh;
  transition: transform 0.5s ease-in;
  transform: translateX(320px);
  /* cursor: pointer; */
}
.offcanvas.open {
  transform: translateX(0);
}
.offcanvas_topRow {
  padding: 30px;
}
.offcanvas_topRow p {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-decoration-line: underline;
  color: #1565d8;
}

.offcanvas_jobTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 30px;
}
.offcanvas_jobTitle img {
  height: 100px;
}
.offcanvas_jobTitle h2 {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: normal;
  letter-spacing: 0.2px;
  color: #183b56;
}
.offcanvas_jobTitle p {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: normal;
  color: #183b56;
}

.offcanvas_jobInfo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  max-width: 623px;
  height: 100px;
  padding: 30px;
}
.offcanvas_jobInfo_detail {
  width: max-content;
  height: auto;
  margin-right: 5px;
}
.offcanvas_jobInfo_detail p {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #183b56;
}
.offcanvas_jobInfo_detail span {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #5a7184;
}

.offcanvas_jobDetails {
  height: 300px;
  overflow-y: scroll;
  width: 100%;
  padding-left: 30px;
  margin-top: 1rem;
}
.offcanvas_jobDetails h4 {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 48px;
  letter-spacing: 0.2px;
  color: #183b56;
}

.offcanvas_jobDetails::-webkit-scrollbar {
  background: #e6e6e6;
  border-radius: 10px;
  width: 8px;
}

.offcanvas_jobDetails::-webkit-scrollbar-thumb {
  background: #3c9f86;
  border-radius: 10px;
  height: 103px;
}

.offcanvas_jobDetails p {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  color: #183b56;
  white-space: pre-wrap;
}

.offcanvas_btn {
  background: #3c9f86;
  border-radius: 4px;
  height: 48px;
  width: 434px;
  text-align: center;
  margin: 0 auto;
  border: none !important;
}

/* yellow color */
.customBdr {
  border-radius: 0 0.85rem 0.85rem 0 !important;
}

.customDropDown .dropdown-menu {
  border: 1px solid #FFD461 !important;
}
.ai-bg {
  background: url('images/ai-bg.png') repeat-y center;
}

.centerBox {
  background: #FFF;
  border: 0 !important;
  border-radius:5px !important;
  /* box-shadow: 0 18px 18px -8px rgba(0, 0, 0, 0.328);
  -webkit-box-shadow: 0 18px 18px -8px rgba(0, 0, 0, 0.328);
  -moz-box-shadow: 0 18px 18px -8px rgba(0, 0, 0, 0.328); */
  /* box-shadow: 0px 0px 30px #ddd; */
}

.boxShadow {
  box-shadow: 0px 0px 30px #ddd !important;
}

.ai-bg-1 {
  background-color: #ffebb3;
}

.centerBox-1 {
  background: #FFF;
  border: 0 !important;
  border-radius:5px !important;
}

.title-yellow-bg {
  background-color: #FFD461 !important;
  border: 1px solid #FFD461 !important;
  border-radius: 3px;
}

.yellow-clr {
  color: #FFD461 !important;
}

.grey-bg {
  background-color: #e4e6ef !important;
}

.yellow-bdr {
  border-color: #FFD461 !important;
}

.yellow-bdr-1px {
  border: 1px solid #FFD461 !important;
}

.yellow-bg {
  background-color: #FFD461 !important;
}

.yellow-span {
  border: 1px solid #FFD461 !important;
  border-radius: 3px;
  color: #FFD461 !important;
}

.grey-br-3 {
  border: 1px solid #e4e6ef !important;
  border-radius: 3px !important;
}

.yellow-br-3 {
  border: 1px solid #FFD461 !important;
  border-radius: 3px !important;
}

.yellow-txt {
  color: #FFD461 !important;
}

.fs-icon:hover {
  color: #FFD461 !important;
}

.btn-hover-yellow:hover {
  color: #3f4254 !important;
  background-color: #FFD461 !important;
  border-color: #FFD461 !important;
}

.ht-30 {
  min-height: 30px !important;
  height: 30px !important;
}
/**/
.bg-common{
  /* width: 48% !important; */
  height: 100vh;
  /* margin:0 !important;
  padding:0 !important; */
}
.one{
background-color: #f0f0f0;
}
.two{
  background-color: #ffd461;
}
.content-div{
  height: auto !important;
  z-index: 1 !important;
  /* top: 50%; left: 50%; */
  /* transform: translate(-50%,-50%); */
  border: 0 !important;
  border-radius:5px !important;
  box-shadow: 0 18px 18px -8px rgba(0, 0, 0, 0.328);
  -webkit-box-shadow: 0 18px 18px -8px rgba(0, 0, 0, 0.328);
  -moz-box-shadow: 0 18px 18px -8px rgba(0, 0, 0, 0.328);
  margin: 20px auto;
  width: 80% !important;
}

.form-check-input[type="checkbox"] {
  border-radius: .25em;
}


.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: .25em !important;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0,0,0,.25);
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}
.sticky-top {
  z-index: 1 !important;
  /* background-color: #c0c1c3 !important; */
}

.customBorderBottom2 {
    border-bottom: 2px solid #c0c1c3 !important;
}

.input-bdr {
  border: 1px solid #CCC !important;
}
.star-container {
  vertical-align: unset !important;
}
/**/
.tag .css-1s2u09g-control {
  min-height: 0 !important;
}
.tag .css-1pahdxg-control {
  min-height: 0 !important;
}
.tag .css-319lph-ValueContainer {
  padding: 0 8px !important;
}
.tag .css-6j8wv5-Input {
  margin: 1px 2px !important;
}
.tag .css-tlfecz-indicatorContainer {
  padding: 0 4px !important;
}
.tag .css-1gtu0rj-indicatorContainer {
  padding: 0 4px !important;
}
.tag .css-26l3qy-menu {
  z-index: 1000 !important;
}
.nav .nav-link.active {
  border-bottom: 2px solid #FFD461 !important;
}
.nav .nav-link.active, .nav .nav-link:hover:not(.disabled), .nav .show > .nav-link {
  color: #3f4254 !important;
}
/* */
.my-span {
  background-color: red !important;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
    /* color: #FFD461 !important; */
    border-bottom: 2px solid #FFD461;
    font-weight: 600;
}