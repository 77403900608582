html, body {
   font-size: 14.5px;
   /* color: #777; */
   color: #3f4254;
   /* background: #f0f6fb; */
   /* background: #01395e; */
   /* background: #FFF; */
   height: auto !important;
}

.radio.radio-primary>input:checked~span {
   background-color: #3699ff !important
}

.form-control {
   border-radius: .28rem;
   border: 1px solid #e4e6ef
}

input[type=text]:focus, input[type=email]:focus, textarea:focus {
   border-color: #3699ff !important
}

.react-date-picker__wrapper {
   border: none !important;
}

.btn {
   border-radius: .50rem;
}

.btn.btn-primary {
   color: #fff;
   background-color: #3699ff;
   border-color: #3699ff
}
.f-clr{
   /* color:#fff; */
  color:#212934;
}
.form-control text-right{
   color:#fff;
}


.btn.btn-primary i {
   color: #fff
}

.btn.btn-primary .svg-icon svg g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease;
   fill: #fff
}

.btn.btn-primary .svg-icon svg:hover g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease
}

.btn.btn-primary.dropdown-toggle:after {
   color: #fff;
   content: '';
}

.btn.btn-primary.focus:not(.btn-text), .btn.btn-primary:focus:not(.btn-text), .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
   color: #fff;
   background-color: #187de4;
   border-color: #187de4
}

.btn.btn-primary.focus:not(.btn-text) i, .btn.btn-primary:focus:not(.btn-text) i, .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i {
   color: #fff
}

.btn.btn-primary.focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease;
   fill: #fff
}

.btn.btn-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease
}

.btn.btn-primary.focus:not(.btn-text).dropdown-toggle:after, .btn.btn-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after {
   color: #fff
}

.btn.btn-primary.disabled, .btn.btn-primary:disabled {
   color: #fff;
   background-color: #3699ff;
   border-color: #3699ff
}

.btn.btn-primary.disabled i, .btn.btn-primary:disabled i {
   color: #fff
}

.btn.btn-primary.disabled .svg-icon svg g [fill], .btn.btn-primary:disabled .svg-icon svg g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease;
   fill: #fff
}

.btn.btn-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-primary:disabled .svg-icon svg:hover g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease
}

.btn.btn-primary.disabled.dropdown-toggle:after, .btn.btn-primary:disabled.dropdown-toggle:after {
   color: #fff
}

.btn.btn-primary:not(:disabled):not(.disabled).active, .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .show .btn.btn-primary.btn-dropdown, .show>.btn.btn-primary.dropdown-toggle {
   color: #fff;
   background-color: #187de4;
   border-color: #187de4
}

.btn.btn-primary:not(:disabled):not(.disabled).active i, .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .show .btn.btn-primary.btn-dropdown i, .show>.btn.btn-primary.dropdown-toggle i {
   color: #fff
}

.btn.btn-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill], .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .show .btn.btn-primary.btn-dropdown .svg-icon svg g [fill], .show>.btn.btn-primary.dropdown-toggle .svg-icon svg g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease;
   fill: #fff
}

.btn.btn-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill], .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .show .btn.btn-primary.btn-dropdown .svg-icon svg:hover g [fill], .show>.btn.btn-primary.dropdown-toggle .svg-icon svg:hover g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease
}

.btn.btn-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after, .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .show .btn.btn-primary.btn-dropdown.dropdown-toggle:after, .show>.btn.btn-primary.dropdown-toggle.dropdown-toggle:after {
   color: #fff
}

.btn.btn-light-primary {
   color: #3699ff;
   background-color: #e1f0ff;
   border-color: transparent
}

.btn.btn-light-primary i {
   color: #3699ff
}

.btn.btn-light-primary .svg-icon svg g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease;
   fill: #3699ff
}

.btn.btn-light-primary .svg-icon svg:hover g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease
}

.btn.btn-light-primary.dropdown-toggle:after {
   color: #3699ff
}

.btn.btn-light-primary.focus:not(.btn-text), .btn.btn-light-primary:focus:not(.btn-text), .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
   color: #fff;
   background-color: #3699ff;
   border-color: transparent
}

.btn.btn-light-primary.focus:not(.btn-text) i, .btn.btn-light-primary:focus:not(.btn-text) i, .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i {
   color: #fff
}

.btn.btn-light-primary.focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-primary:focus:not(.btn-text) .svg-icon svg g [fill], .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease;
   fill: #fff
}

.btn.btn-light-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill], .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease
}

.btn.btn-light-primary.focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-primary:focus:not(.btn-text).dropdown-toggle:after, .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after {
   color: #fff
}

.btn.btn-light-primary.disabled, .btn.btn-light-primary:disabled {
   color: #3699ff;
   background-color: #e1f0ff;
   border-color: transparent
}

.btn.btn-light-primary.disabled i, .btn.btn-light-primary:disabled i {
   color: #3699ff
}

.btn.btn-light-primary.disabled .svg-icon svg g [fill], .btn.btn-light-primary:disabled .svg-icon svg g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease;
   fill: #3699ff
}

.btn.btn-light-primary.disabled .svg-icon svg:hover g [fill], .btn.btn-light-primary:disabled .svg-icon svg:hover g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease
}

.btn.btn-light-primary.disabled.dropdown-toggle:after, .btn.btn-light-primary:disabled.dropdown-toggle:after {
   color: #3699ff
}

.btn.btn-light-primary:not(:disabled):not(.disabled).active, .btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text), .show .btn.btn-light-primary.btn-dropdown, .show>.btn.btn-light-primary.dropdown-toggle {
   color: #fff;
   background-color: #3699ff;
   border-color: transparent
}

.btn.btn-light-primary:not(:disabled):not(.disabled).active i, .btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) i, .show .btn.btn-light-primary.btn-dropdown i, .show>.btn.btn-light-primary.dropdown-toggle i {
   color: #fff
}

.btn.btn-light-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill], .btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill], .show .btn.btn-light-primary.btn-dropdown .svg-icon svg g [fill], .show>.btn.btn-light-primary.dropdown-toggle .svg-icon svg g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease;
   fill: #fff
}

.btn.btn-light-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill], .btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill], .show .btn.btn-light-primary.btn-dropdown .svg-icon svg:hover g [fill], .show>.btn.btn-light-primary.dropdown-toggle .svg-icon svg:hover g [fill] {
   -webkit-transition: fill .3s ease;
   transition: fill .3s ease
}

.btn.btn-light-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after, .btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after, .show .btn.btn-light-primary.btn-dropdown.dropdown-toggle:after, .show>.btn.btn-light-primary.dropdown-toggle.dropdown-toggle:after {
   color: #fff
}
.btn.btn-outline-primary {
   color:#6993ff;
   background-color:transparent;
   border-color:#6993ff
  }
  .btn.btn-outline-primary i {
   color:#6993ff
  }
  .btn.btn-outline-primary .svg-icon svg g [fill] {
   -webkit-transition:fill .3s ease;
   transition:fill .3s ease;
   fill:#6993ff
  }
  .btn.btn-outline-primary .svg-icon svg:hover g [fill] {
   -webkit-transition:fill .3s ease;
   transition:fill .3s ease
  }
  .btn.btn-outline-primary.dropdown-toggle:after {
   color:#6993ff
  }
  .btn.btn-outline-primary.focus:not(.btn-text),
  .btn.btn-outline-primary:focus:not(.btn-text),
  .btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
   color:#fff;
   background-color:#6993ff;
   border-color:#6993ff
  }
  .btn.btn-outline-primary.focus:not(.btn-text) i,
  .btn.btn-outline-primary:focus:not(.btn-text) i,
  .btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled) i {
   color:#fff
  }
  .btn.btn-outline-primary.focus:not(.btn-text) .svg-icon svg g [fill],
  .btn.btn-outline-primary:focus:not(.btn-text) .svg-icon svg g [fill],
  .btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg g [fill] {
   -webkit-transition:fill .3s ease;
   transition:fill .3s ease;
   fill:#fff
  }
  .btn.btn-outline-primary.focus:not(.btn-text) .svg-icon svg:hover g [fill],
  .btn.btn-outline-primary:focus:not(.btn-text) .svg-icon svg:hover g [fill],
  .btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled) .svg-icon svg:hover g [fill] {
   -webkit-transition:fill .3s ease;
   transition:fill .3s ease
  }
  .btn.btn-outline-primary.focus:not(.btn-text).dropdown-toggle:after,
  .btn.btn-outline-primary:focus:not(.btn-text).dropdown-toggle:after,
  .btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled).dropdown-toggle:after {
   color:#fff
  }
  .btn.btn-outline-primary.disabled,
  .btn.btn-outline-primary:disabled {
   color:#6993ff;
   background-color:transparent;
   border-color:#6993ff
  }
  .btn.btn-outline-primary.disabled i,
  .btn.btn-outline-primary:disabled i {
   color:#6993ff
  }
  .btn.btn-outline-primary.disabled .svg-icon svg g [fill],
  .btn.btn-outline-primary:disabled .svg-icon svg g [fill] {
   -webkit-transition:fill .3s ease;
   transition:fill .3s ease;
   fill:#6993ff
  }
  .btn.btn-outline-primary.disabled .svg-icon svg:hover g [fill],
  .btn.btn-outline-primary:disabled .svg-icon svg:hover g [fill] {
   -webkit-transition:fill .3s ease;
   transition:fill .3s ease
  }
  .btn.btn-outline-primary.disabled.dropdown-toggle:after,
  .btn.btn-outline-primary:disabled.dropdown-toggle:after {
   color:#6993ff
  }
  .btn.btn-outline-primary:not(:disabled):not(.disabled).active,
  .btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text),
  .show .btn.btn-outline-primary.btn-dropdown,
  .show>.btn.btn-outline-primary.dropdown-toggle {
   color:#fff;
   background-color:#6993ff;
   border-color:#6993ff
  }
  .btn.btn-outline-primary:not(:disabled):not(.disabled).active i,
  .btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text) i,
  .show .btn.btn-outline-primary.btn-dropdown i,
  .show>.btn.btn-outline-primary.dropdown-toggle i {
   color:#fff
  }
  .btn.btn-outline-primary:not(:disabled):not(.disabled).active .svg-icon svg g [fill],
  .btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg g [fill],
  .show .btn.btn-outline-primary.btn-dropdown .svg-icon svg g [fill],
  .show>.btn.btn-outline-primary.dropdown-toggle .svg-icon svg g [fill] {
   -webkit-transition:fill .3s ease;
   transition:fill .3s ease;
   fill:#fff
  }
  .btn.btn-outline-primary:not(:disabled):not(.disabled).active .svg-icon svg:hover g [fill],
  .btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text) .svg-icon svg:hover g [fill],
  .show .btn.btn-outline-primary.btn-dropdown .svg-icon svg:hover g [fill],
  .show>.btn.btn-outline-primary.dropdown-toggle .svg-icon svg:hover g [fill] {
   -webkit-transition:fill .3s ease;
   transition:fill .3s ease
  }
  .btn.btn-outline-primary:not(:disabled):not(.disabled).active.dropdown-toggle:after,
  .btn.btn-outline-primary:not(:disabled):not(.disabled):active:not(.btn-text).dropdown-toggle:after,
  .show .btn.btn-outline-primary.btn-dropdown.dropdown-toggle:after,
  .show>.btn.btn-outline-primary.dropdown-toggle.dropdown-toggle:after {
   color:#fff
  }

    .font-weight-bolder {
      font-weight: 600 !important;
   }
   .box-shadow-none {
      box-shadow: none ! important;
   }
   .text-dark {
      color : #181c32cf !important
   }
   .custom-card-title {
      font-weight: 500 !important;
      font-size: 1.27em;
      color: black;
   }
   .custom-container {
      padding: 0 32px;
   }
   .border-bottom {
      border-bottom: 1px solid #ebedf3 !important;
   }
    .comment-box {
      border-radius: 5px;
      position: relative;
      border: 1px solid #3699ff;
      padding: 5px inherit;
   }
   .comment-button i {
   display: none;
}
.error{
   color:#f64e60;
}

.react-datepicker-wrapper{
   width:500px
}
.image-container {
   border: 1px solid #f2f3f7;
   padding: 20px 10px;
   background-color: white;
   border-radius: 5px;
   height: 80vh;
   scrollbar-width: thin;
   overflow:auto
   
}
.image-block {
   background-color: white;
   padding-top: 10px;
   border-radius: 5px;
   box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
   height: 100%;
}
.custom-label {
   padding: 10px 20px 10px 20px;
   box-sizing: border-box;
   font-weight: 700;
}
button:hover > .hover-white {
   color: white !important
}

.bg-custom {
   /* background-color:#a8244f !important; */
   /* background-color: #861b3e !important;*/
   /* background-color: #976668 !important; */
   /* background-color: #D89A9F !important; */
   /* background-color: #3699ff !important; */
   /* background-color: #F2F2F2 !important; */
   /* background-color: #001b2e !important; */
   background-color: #fff !important;
   /* box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1); */
}

.customClr {
   color: #212934 !important;
   /* color: #f5f5f6 !important; */
}

.footer {
   height: 40px;
   /* position: absolute;
   bottom: 0;
   width: 100%;
   height: 60px;
   line-height: 60px;*/
   background-color: #f5f5f5;
}

.btn-c-primary, .btn.btn-hover-c-primary.active{
   background-color: #3699ff !important;
   border-color: #3699ff !important;
   color: #FFFFFF !important;
}

.btn.btn-hover-c-primary.focus:not(.btn-text), .btn.btn-hover-c-primary:focus:not(.btn-text), .btn.btn-hover-c-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
   background-color: #3699ff !important;
   border-color: #3699ff !important;
   color: #FFFFFF !important;
}

.btn-light-c-primary {
   background-color: #f0f6fb  !important;
   /*border-color: #3699ff !important;*/
   color: #3f4254 !important;
}

.btn.btn-light-c-primary.focus:not(.btn-text), .btn.btn-light-c-primary:focus:not(.btn-text), .btn.btn-light-c-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
   background-color: #3699ff !important;
   border-color: #3699ff !important;
   color: #FFFFFF !important;
}

.text-c-primary {
   color: #3699ff !important;
}

.offcanvas {
   margin-top: 40px;
}

.text-ellipsis {
   white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card.card-list > .card-body {
   padding: 1.25rem 1.25rem;
}
.profile-cancel-button {
    position: absolute;
    right: -10px;
    bottom: -7px;
}

.label.label-light-custom {
   color: #fff;
   background-color: #861b3e;
}

.dropdown-item.active,.dropdown-item:active{
   color: #0f0f0f !important;
   text-decoration: none;
   background-color: #FFD461 !important;
}

.css-1s2u09g-control {
   border-color: #FFD461 !important;
}

.navbar-toggle{background:red;}
.navbar-toggle .icon-bar{background:white;}

.home div.card-body {
 border-radius: 10px;
 border: 0;
 box-shadow: #daebfd 0px 3px 5px 0px
}
.home div.card-body:hover svg {
   color:  #3699ff !important;
}
.home div.card-body:hover{
  /*background-color:  #3699ff;*/
  color:  #3699ff !important;
  border-radius: 10px !important;
  cursor:  pointer !important;
  transition: background-color 0.5s ease-in, box-shadow 0.5 ease;
  box-shadow: #daebfd 0px 7px 29px 0px
}
.grid-images .notes{
  /*  max-height: 60vh !important; */
}
.grid-images .card{
   margin-right: 25px;
}
@media screen and (max-width: 991px) {
  .grid-images .notes {
   /*  max-height:50vh !important; */
  }
  .grid-images .card{
   margin-right: 0;
	}
}
.imageGrid  input[type='checkbox']>span::after{
	border-color: #d28989 !important
}

.imageGrid input[type='checkbox']+span {
	display: none !important
}
.imageGrid input[type='checkbox']:checked+span{
	display: inherit !important
}
.imageGrid .figure:hover input[type='checkbox']+span{
	display: inherit !important
}
.bg-white {
   background-color: #FFF !important;
}
.border-dashed {
   border-style: dashed !important;
}
.border-gray-300 {
   border-color: #b5b5c3 !important;
}
.badge-light-primary {
   color: #009ef7;
   background-color: #f1faff;
}
.text-gray-400 {
   color: #b5b5c3 !important;
}
.bg-gray {
   background-color: #F8FAFD;
}
.nav .nav-link.active {
   font-weight: 600 !important;
   border-bottom: 2px solid #3699ff;
}
.customTabs .nav .nav-link:hover {
   border-bottom: 2px solid #3699ff !important;
}
.no-box-shadow {
   box-shadow: none !important;
}
.border-grey {
   border:1px solid #EBEDF3 !important;
}