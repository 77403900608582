
.about-card {
    flex: 1 1 350px;
    padding: 30px;
    transform: translateX(150%);
    animation: about-card-animation 1s ease-in-out forwards;
    
  }
  
  @keyframes about-card-animation {
    100% {
      transform: translate(0);
    }
  }

  /* .about-card card {
    display: block;
    max-width: 100%;
    margin: auto;
    object-fit: cover;
    margin-bottom: 10px;
    object-position: right;
  } */

  .editorWidth1 {
    width: 98% !important;
    height: 35rem;
    background-color: hsl(220, 100%, 99%) !important;
    border: 1px solid #131313 !important;
    box-shadow: 0 0px 10px #010206 !important;  /* 8093c6 */
    margin-left: 1% !important;
    margin-bottom: 1rem !important;
}

.defaultEditorStyle1 {
    color: #f2f2f2 !important;
}

.navst{
    flex: 1 1 350px;
    /* padding: px; */
    transform: translateX(-250%);
    animation: navst-animation 1s ease-in-out forwards;
    color:#131313 !important;
    
  }

  @keyframes navst-animation {
    100% {
      transform: translate(0);
    }
  }
.hd{
  height: 10px;
}
 


/* .btn-primary  {
    background-color: #15133C !important;
} */



  /* .about-card {
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0 0px 20px 0 rgba(0,0,0,0.2);
    background-color: rgba(0,0,0,0.2);
    transition: 0.3s;
 } */


